var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "section",
    [
      _c("div", { staticClass: "m-1 mr-4 px-0 flex w-full justify-between" }, [
        _c(
          "div",
          { staticClass: "w-full" },
          [
            _c("breadcrumb", {
              attrs: {
                title: _vm.proposta.TypeName,
                actualPage:
                  _vm.proposta.ProposalTypeId == 2
                    ? "Acompanhamento do endosso"
                    : "Acompanhamento da proposta",
                previousPage: "propostas",
                previousPageTitle: "Lista de propostas e cotações",
              },
            }),
          ],
          1
        ),
        _vm.podeCancelarProposta || _vm.podeRealizarEmissao
          ? _c(
              "div",
              { staticClass: "w-full mt-5" },
              [
                _c(
                  "vs-col",
                  {
                    attrs: {
                      "vs-type": "flex",
                      "vs-justify": "end",
                      "vs-align": "end",
                    },
                  },
                  [
                    _vm.podeCancelarProposta
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "button-default",
                            attrs: { type: "filled" },
                            on: {
                              click: function ($event) {
                                return _vm.cancelarProposta(
                                  _vm.proposta.UniqueId
                                )
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.proposta.ProposalTypeId == 1
                                    ? "Cancelar proposta"
                                    : "Cancelar endosso"
                                ) +
                                "\n        "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.podeRealizarEmissao
                      ? _c(
                          "vs-button",
                          {
                            staticClass: "button-primary ml-5",
                            attrs: { type: "filled" },
                            on: {
                              click: function ($event) {
                                _vm.proposta.ProposalTypeId == 1
                                  ? _vm.seguirComEmissao()
                                  : _vm.seguirComEndosso()
                              },
                            },
                          },
                          [
                            _vm.proposta.ProposalTypeId == 1
                              ? _c("p", [_vm._v("Seguir com emissão")])
                              : _vm.proposta.Endorsement
                              ? _c("p", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.proposta.Endorsement
                                          .EndorsementReasonId == 18
                                          ? "Escolher parcelamento"
                                          : "Emitir endosso"
                                      ) +
                                      "\n            "
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            )
          : _vm._e(),
      ]),
      _vm.proposta.Number
        ? _c(
            "vs-card",
            { staticClass: "mt-4 p-8 dados-proposta" },
            [
              _c("h3", { staticClass: "text-primary mb-3" }, [
                _vm._v(
                  "\n      Número\n      " +
                    _vm._s(
                      _vm.proposta.ProposalTypeId == 1
                        ? "da proposta:"
                        : "da proposta de endosso:"
                    ) +
                    "\n      "
                ),
                _c("b", [_vm._v(_vm._s(_vm.proposta.Number))]),
              ]),
              _c("p", { staticClass: "mb-5 font-semibold" }, [
                _vm._v(
                  "\n      Data " +
                    _vm._s(
                      _vm.proposta.ProposalTypeId == 1
                        ? "da proposta:"
                        : "do endosso:"
                    ) +
                    "\n      " +
                    _vm._s(
                      _vm._f("dateFormat")(
                        new Date(_vm.proposta.CreateDate),
                        "DD/MM/YYYY"
                      )
                    ) +
                    "\n    "
                ),
              ]),
              _vm.podeExibirTagCanalCorretorMapfre
                ? _c("TagCanalCorretorMapfre", {
                    staticClass: "mb-6",
                    attrs: {
                      tipoCanalCorretora: _vm.tipoCanalCorretora,
                      view: "timeline",
                    },
                  })
                : _vm._e(),
              _c(
                "div",
                { staticClass: "flex mt-2 mb-10" },
                [
                  _vm.podeGerarMinuta()
                    ? _c(
                        "vs-button",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.gerarMinutaClick(_vm.proposta.UniqueId)
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-semibold pr-2 pl-2" },
                            [_vm._v("\n          Download da minuta\n        ")]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.verificaSeBoletoEmitido()
                    ? _c(
                        "vs-button",
                        {
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              _vm.parcelasModal = true
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-semibold pr-2 pl-2" },
                            [
                              _vm._v(
                                "\n          Download do(s) boleto(s)\n        "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.verificaSeStatusEhEmitida()
                    ? _c(
                        "vs-button",
                        {
                          staticClass: "ml-5",
                          attrs: { color: "primary" },
                          on: {
                            click: function ($event) {
                              return _vm.downloadApoliceClick(
                                _vm.proposta.UniqueId
                              )
                            },
                          },
                        },
                        [
                          _c(
                            "span",
                            { staticClass: "font-semibold pr-2 pl-2" },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.proposta.ProposalTypeId == 2
                                      ? "Download do endosso "
                                      : " Download da apólice "
                                  ) +
                                  "\n        "
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                1
              ),
              _c("div", { staticClass: "vx-row px-4 relative" }, [
                _c(
                  "div",
                  { staticClass: "vx-col w-full container-timeline p-5" },
                  [
                    _c("div", { staticClass: "vx-row px-4 relative" }, [
                      _c("div", { staticClass: "vx-col w-full p-5" }, [
                        _c("div", { staticClass: "macro-timeline" }, [
                          (_vm.proposta || {}).Timeline
                            ? _c(
                                "ul",
                                { staticClass: "status" },
                                _vm._l(
                                  ["Cotação", "Proposta", "Emissão"],
                                  function (status, index) {
                                    return _c("li", { key: index }, [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "material-icons",
                                          class: _vm.getTimelineColor(
                                            index + 1
                                          ),
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.getTimelineIcon(index + 1)
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                      _c("p", [
                                        _c("b", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.proposta.ProposalTypeId ==
                                                2 && index == 0
                                                ? "Endosso"
                                                : status
                                            )
                                          ),
                                        ]),
                                      ]),
                                    ])
                                  }
                                ),
                                0
                              )
                            : _vm._e(),
                        ]),
                      ]),
                    ]),
                    _c("vs-row", { staticClass: "vx-row px-4" }, [
                      _c(
                        "div",
                        { staticClass: "vx-col w-1/2 pl-20" },
                        [
                          _vm.proposta.ProposalTypeId == 2
                            ? _c(
                                "vs-col",
                                {
                                  attrs: {
                                    "vs-type": "flex",
                                    "vs-justify": "end",
                                    "vs-align": "end",
                                    "vs-w": "12",
                                  },
                                },
                                [
                                  _c("visualizar-alteracoes", {
                                    attrs: {
                                      changed: _vm.diffEndosso.length > 0,
                                      showDesfazer: false,
                                      objectDiff: _vm.diffEndosso,
                                      responsavel:
                                        _vm.responsavelDataUpdateEndosso,
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                          _c(
                            "div",
                            { staticClass: "block-info pr-20" },
                            [
                              _c("div", { staticClass: "info" }, [
                                _c("h4", { staticClass: "text-primary" }, [
                                  _vm._v("Tomador"),
                                ]),
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.proposta.PolicyHolderBranch
                                          ? _vm.proposta.PolicyHolderBranch
                                              .Name || {}
                                          : _vm.proposta.PolicyHolder.Name || {}
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("maskCpfCnpj")(
                                          _vm.proposta.PolicyHolderBranch
                                            ? (
                                                _vm.proposta
                                                  .PolicyHolderBranch || {}
                                              ).CpfCnpj
                                            : (_vm.proposta.PolicyHolder || {})
                                                .CpfCnpj
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "info" }, [
                                _c("h4", { staticClass: "text-primary" }, [
                                  _vm._v("Segurado"),
                                ]),
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (_vm.seguradoDaProposta || {}).Name
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      _vm._f("maskCpfCnpj")(
                                        (_vm.seguradoDaProposta || {}).CpfCnpj
                                      )
                                    )
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "info" }, [
                                _c("h4", { staticClass: "text-primary" }, [
                                  _vm._v("Grupo/Modalidade"),
                                ]),
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        (
                                          (_vm.proposta || {}).ModalityGroup ||
                                          {}
                                        ).Name
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                                _c("span", [
                                  _vm._v(
                                    _vm._s(
                                      ((_vm.proposta || {}).Modality || {}).Name
                                    )
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "info" }, [
                                _c("h4", { staticClass: "text-primary" }, [
                                  _vm._v("Importância Segurada"),
                                ]),
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("maskCurrency")(
                                          (_vm.proposta || {})
                                            .InsuredAmountValue
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "info" }, [
                                _c("h4", { staticClass: "text-primary" }, [
                                  _vm._v("Prêmio"),
                                ]),
                                _c("p", { staticClass: "font-semibold" }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm._f("maskCurrency")(
                                          (_vm.proposta || {}).InsurancePremium
                                        )
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]),
                              (_vm.proposta || {}).ProposalTypeId == 2
                                ? [
                                    _c(
                                      "h2",
                                      { staticClass: "text-primary mb-5" },
                                      [_vm._v("Dados da Apólice")]
                                    ),
                                    _c("div", { staticClass: "info" }, [
                                      _c(
                                        "h4",
                                        { staticClass: "text-primary" },
                                        [_vm._v("Número")]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                (
                                                  (_vm.proposta || {}).Policy ||
                                                  {}
                                                ).Number
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "info" }, [
                                      _c(
                                        "h4",
                                        { staticClass: "text-primary" },
                                        [_vm._v("Data de Emissão")]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                _vm.$utils.dateToDDMMYYYY(
                                                  (
                                                    (_vm.proposta || {})
                                                      .Policy || {}
                                                  ).CreateDate
                                                )
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    _c("div", { staticClass: "info" }, [
                                      _c(
                                        "h4",
                                        { staticClass: "text-primary" },
                                        [_vm._v("Vigência")]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "font-semibold" },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(
                                                `${_vm.$utils.dateToDDMMYYYY(
                                                  (
                                                    (_vm.proposta || {})
                                                      .Policy || {}
                                                  ).StartDate
                                                )} à ${_vm.$utils.dateToDDMMYYYY(
                                                  (
                                                    (_vm.proposta || {})
                                                      .Policy || {}
                                                  ).EndDate
                                                )}`
                                              ) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ]),
                                    (_vm.proposta || {}).Reference
                                      ? _c(
                                          "div",
                                          { staticClass: "info" },
                                          [
                                            _c(
                                              "router-link",
                                              {
                                                attrs: {
                                                  to: {
                                                    name: "timeline-proposta",
                                                    params: {
                                                      propostaUniqueId:
                                                        _vm.proposta.Reference,
                                                    },
                                                  },
                                                  target: "_blank",
                                                },
                                              },
                                              [
                                                _c(
                                                  "vs-button",
                                                  {
                                                    staticClass:
                                                      "font-bold p-0",
                                                    attrs: {
                                                      color: "primary",
                                                      type: "flat",
                                                      icon: "search",
                                                      "icon-after": "",
                                                      size: "large",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                      Ver proposta da apólice\n                    "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ],
                        1
                      ),
                      _c(
                        "div",
                        { staticClass: "vx-col w-1/2 pl-20 pr-20" },
                        [
                          _c(
                            "h2",
                            { staticClass: "mb-10 text-primary font-semibold" },
                            [
                              _vm._v(
                                "\n              Histórico de movimentação\n            "
                              ),
                            ]
                          ),
                          [
                            _c(
                              "ul",
                              { staticClass: "vx-timeline" },
                              _vm._l(
                                _vm.timelineHistory || [],
                                function (item) {
                                  return _c(
                                    "li",
                                    { key: item.Id, staticClass: "mb-5" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "timeline-icon",
                                          class: _vm.getTimelineItemColor(item),
                                        },
                                        [
                                          _c("feather-icon", {
                                            attrs: {
                                              icon: _vm.getTimelineItemIcon(
                                                item
                                              ),
                                              svgClasses:
                                                "text-white stroke-current w-5 h-5",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "timeline-info" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "step-title font-semibold",
                                            },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(item.Situation.Name) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "flex" },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "font-weight": "bold",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                        " +
                                                      _vm._s(item.Observation) +
                                                      "\n                      "
                                                  ),
                                                ]
                                              ),
                                              _c("span", {
                                                attrs: {
                                                  "v-html":
                                                    _vm.getTextTimelineHistory(
                                                      item
                                                    ),
                                                },
                                              }),
                                              _vm.exibeBtnDetalhamento(item)
                                                ? _c(
                                                    "vs-button",
                                                    {
                                                      staticClass:
                                                        "bg-warning button-documents",
                                                      attrs: { type: "filled" },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.popupDetalhamento = true
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        { staticClass: "flex" },
                                                        [
                                                          _c("i", {
                                                            staticClass:
                                                              "onpoint-eye mr-2 my-auto",
                                                          }),
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "text",
                                                            },
                                                            [
                                                              _vm._v(
                                                                "\n                            Visualizar Follow Up/Documentos\n                          "
                                                              ),
                                                            ]
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ]
                                      ),
                                      _c("div", [
                                        _c(
                                          "small",
                                          { staticClass: "text-grey" },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.formatActivityDate(
                                                  item.CreateByPerson.Name,
                                                  item.CreateDate
                                                )
                                              )
                                            ),
                                          ]
                                        ),
                                      ]),
                                    ]
                                  )
                                }
                              ),
                              0
                            ),
                          ],
                          _c("ul", { staticClass: "flex items-center mt-10" }, [
                            _c("li", { staticClass: "flex items-center" }, [
                              _c(
                                "span",
                                { staticClass: "material-icons text-success" },
                                [_vm._v("fiber_manual_record")]
                              ),
                              _c("span", [_vm._v("Aprovado")]),
                            ]),
                            _c(
                              "li",
                              { staticClass: "flex items-center ml-4" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "material-icons text-primary-orig",
                                  },
                                  [_vm._v("fiber_manual_record")]
                                ),
                                _c("span", [_vm._v("Seguradora")]),
                              ]
                            ),
                            _c(
                              "li",
                              { staticClass: "flex items-center ml-4" },
                              [
                                _c(
                                  "span",
                                  {
                                    staticClass: "material-icons text-warning",
                                  },
                                  [_vm._v("fiber_manual_record")]
                                ),
                                _c("span", [_vm._v("Corretor")]),
                              ]
                            ),
                            _c(
                              "li",
                              { staticClass: "flex items-center ml-4" },
                              [
                                _c(
                                  "span",
                                  { staticClass: "material-icons text-danger" },
                                  [_vm._v("fiber_manual_record")]
                                ),
                                _c("span", [_vm._v("Recusado")]),
                              ]
                            ),
                          ]),
                        ],
                        2
                      ),
                    ]),
                  ],
                  1
                ),
              ]),
            ],
            1
          )
        : _vm._e(),
      _c("listar-parcelas-popup", {
        attrs: {
          parcelasModal: _vm.parcelasModal,
          propostaUniqueId: _vm.$route.params.propostaUniqueId,
          autoLoad: true,
        },
        on: {
          fecharParcelasModal: function ($event) {
            _vm.parcelasModal = false
          },
        },
      }),
      _c("required-documents", {
        attrs: {
          showModal: _vm.popupDetalhamento,
          detalhamento: _vm.detalhamento,
          showFollowUp: _vm.proposta.StatusId !== 6,
          propostaUniqueId: _vm.$route.params.propostaUniqueId,
        },
        on: {
          closeModal: _vm.closeModalDocuments,
          dismissModal: _vm.dismissModalDocuments,
          updateDocs: _vm.updateDocs,
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }